<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getPopusti)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/popusti/create')" v-if="$utils('roleUser', 'Popusti', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table
					v-if="popusti.length"
					:data="popusti"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getPopusti)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="left"
						prop="naziv"
						:label="$t('popusti.naziv')"
						sortable="custom">
					<template slot-scope="scope">
						{{ scope.row.naziv }}<br>
						[ {{ scope.row.model }} ]
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="model"
						:label="$t('popusti.datum')">
					<template slot-scope="scope">
						<p>{{$utils('formatDate', scope.row.datum_od)}}</p>
						<p>{{$utils('formatDate', scope.row.datum_do)}}</p>
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="id_linije"
						:label="$t('popusti.linije')">
					<template slot-scope="scope">
						{{ $utils('loIsArray', scope.row.id_linije) && scope.row.id_linije.length > 0 ? scope.row.id_linije.join(', ') : '-' }}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						:label="$t('popusti.vreme')">
					<template slot-scope="scope">
						<p>{{scope.row.vreme_od}}</p>
						<p>{{scope.row.vreme_do}}</p>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						:label="$t('popusti.kartaDatum')">
					<template slot-scope="scope">
						<p>{{$utils('formatDate', scope.row.karta_datum_od)}}</p>
						<p>{{$utils('formatDate', scope.row.karta_datum_do)}}</p>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="dani"
						:label="$t('popusti.dani')">
					<template slot-scope="scope">
							<span v-for="(dan, index) in scope.row.dani" :key="dan">
								{{$t('global.dani.' + dan)}}{{scope.row.dani.length - 1 > index ? ', ' : ''}}
							</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="dani"
						:label="$t('popusti.daniPovratak')">
					<template slot-scope="scope">
							<span v-for="(dan, index) in scope.row.dani_povratak" :key="dan">
								{{$t('global.dani.' + dan)}}{{scope.row.dani.length - 1 > index ? ', ' : ''}}
							</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="aktivno"
						:label="$t('popusti.aktivno')"
						sortable="custom"
						width="120">
					<template slot-scope="scope">
						<el-button size="mini" @click="statusUpdate(scope.row.id)">
							<i v-if="scope.row.aktivno === 'NE'" class="fas fa-times-circle color-text--red"></i>
							<i v-else-if="scope.row.aktivno === 'DA'" class="fas fa-check-circle color-text--green"></i>
						</el-button>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Popusti', 'edit')">
							<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/popusti/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Popusti', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('popusti', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getPopusti" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'popusti-list',
	data() {
		return {
			popusti: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('popusti.popusti')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getPopusti()]);
	},
	methods: {
		getPopusti() {
			this.gridLoad = true;
			return this.$get('popusti', this.$utils('getParams')).then(data => {
				this.popusti = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		},
		statusUpdate(id) {
			this.$confirm(this.$t('confirm.opisPromeniStatus'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$put('popustiStatus', { id: id }, {}).then(() => {
					this.getPopusti();
				}).catch(() => {
					this.gridLoad = false;
				});
			});
		}
	}
};
</script>
